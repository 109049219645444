import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-center items-center text-center px-12 py-24" }
const _hoisted_2 = { class: "text-12 text-center" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "text-ar-main-text text-base font-bold mt-8 text-center" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "text-12 mt-8 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createTextVNode(" Once you tap \"Let's Go!\", you agree to the "),
      _createElementVNode("a", {
        class: "link",
        href: _ctx.externalLinks.cookiesPolicyLink,
        target: "_blank"
      }, "use of cookies", 8, _hoisted_3),
      _createTextVNode(" to enable the site to work and to measure usage and engagement. ")
    ]),
    _createElementVNode("p", _hoisted_4, [
      _createElementVNode("a", {
        class: "link",
        href: _ctx.externalLinks.privacyPolicyLink,
        target: "_blank"
      }, "Privacy policy", 8, _hoisted_5),
      _createTextVNode(" & "),
      _createElementVNode("a", {
        class: "link",
        href: _ctx.externalLinks.termsOfService,
        target: "_blank"
      }, "Terms of service", 8, _hoisted_6)
    ]),
    _createElementVNode("p", _hoisted_7, " © " + _toDisplayString(_ctx.year) + " " + _toDisplayString(_ctx.appName.fullName) + ". All right reserved. ", 1)
  ]))
}