
import { defineComponent, onBeforeUnmount, onMounted, PropType, ref } from 'vue'

import { IQRCodePoster } from '@/types'

export default defineComponent({
  name: 'AnchorsPreview',

  components: {},

  props: {
    posters: {
      type: Array as PropType<IQRCodePoster[]>,
      default: () => [],
    },
  },

  setup(props) {
    const changePosterImagesInterval = ref<number | null>(null)
    const activePosterIndex = ref(0)

    onMounted(() => {
      if (props.posters.length > 1) {
        startChangePosterImagesInterval()
      }
    })

    onBeforeUnmount(() => {
      stopChangePosterImagesInterval()
    })

    const startChangePosterImagesInterval = () => {
      changePosterImagesInterval.value = setInterval(() => {
        const nextActivePosterIndex = activePosterIndex.value + 1
        if (nextActivePosterIndex > props.posters.length - 1) {
          activePosterIndex.value = 0
        } else {
          activePosterIndex.value = nextActivePosterIndex
        }
      }, 3000)
    }

    const stopChangePosterImagesInterval = () => {
      if (changePosterImagesInterval.value) {
        clearInterval(changePosterImagesInterval.value)
        changePosterImagesInterval.value = null
      }
    }

    return {
      activePosterIndex,
    }
  },
})
