export const acceptFileFormats = {
  arAnchor: '.zpt',
  image: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/avif',
    'image/webp',
    'image/bmp',
    'image/heic',
    'image/heif',
    'image/hevc',
  ],
  video: [
    'video/quicktime',
    'video/mp4',
    'video/x-ms-asf',
    'video/x-msvideo',
    'video/3gpp',
    'video/MP2T',
  ],
}

export const maxFileSizesInMb = {
  arAnchor: 10,
  image: 50,
  video: 400,
}

export const appName = {
  fullName: 'Live Posters',
}

export const externalLinks = {
  privacyPolicyLink: 'https://live-posters.com/policies/privacy-policy',
  termsOfService: 'https://live-posters.com/policies/terms-of-service',
  supportLink: 'https://live-posters.com/pages/contact-us',
  cookiesPolicyLink:
    'https://live-posters.com/policies/privacy-policy/#cookies',
}
