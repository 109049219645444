import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.isLoading,
    class: "py-10 px-24 h-44 text-white font-bold text-base rounded-25 bg-ar-main-green w-150 disabled:hover:bg-ar-main-green hover:bg-ar-main-green-light transition transform duration-300"
  }, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component)))
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}