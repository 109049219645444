import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ar-view-main-wrap w-full h-full bg-ar-main text-ar-main-text font-poppins" }
const _hoisted_2 = {
  key: 0,
  class: "w-full h-full flex flex-col justify-center items-center"
}
const _hoisted_3 = { class: "w-full h-full flex flex-col justify-center items-center" }
const _hoisted_4 = { class: "flex justify-center flex-col items-center relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Footer = _resolveComponent("Footer")!
  const _component_AR = _resolveComponent("AR")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isARActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.arComponent), {
                    type: _ctx.error,
                    "show-button": _ctx.isOrderQueryExists && !_ctx.error,
                    loading: _ctx.loading,
                    onOnStartClick: _ctx.onStartClick
                  }, null, 40, ["type", "show-button", "loading", "onOnStartClick"]))
                ]),
                _: 1
              })
            ])
          ]),
          _createVNode(_component_Footer)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isARActive && _ctx.isOrderQueryExists && _ctx.posters.length > 0 && !_ctx.loading)
      ? (_openBlock(), _createBlock(_component_AR, {
          key: 1,
          posters: _ctx.posters
        }, null, 8, ["posters"]))
      : _createCommentVNode("", true)
  ]))
}