
import { defineComponent, PropType } from 'vue'

import StartButton from './StartButton.vue'

export default defineComponent({
  name: 'ArLoading',

  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    showButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['onStartClick'],

  components: {
    StartButton,
  },

  setup(_, { emit }) {
    const onStartClick = () => {
      emit('onStartClick')
    }

    return {
      onStartClick,
    }
  },
})
