
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { asyncTimeout } from '@/core/helpers'
import { TARViewErrors, IQRCodePoster } from '@/types'
import { ordersService } from '@/services'

import Loading from './components/Loading.vue'
import ErrorView from './components/ErrorView.vue'
import Footer from './components/Footer.vue'
import AR from './components/AR.vue'

export default defineComponent({
  name: 'ArView',

  components: {
    Loading,
    ErrorView,
    Footer,
    AR,
  },

  beforeRouteEnter(to, from, next) {
    // TODO - TEMP FIX
    // const appendScript = (src: string) => {
    //   const head = document.getElementsByTagName('head')[0]
    //   const script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.async = false
    //   script.defer = true
    //   script.src = src
    //   head.appendChild(script)
    // }
    // appendScript('/scripts/aframe-1.5.0.min.js')
    // appendScript('/scripts/zappar-aframe-2.2.2/zappar-aframe.js')
    // appendScript('/scripts/aframe-troika-text.min.js')

    document.body.classList.add('overflow-hidden')

    next()
  },

  beforeRouteLeave(to, from, next) {
    document.body.classList.remove('overflow-hidden')

    next()
  },

  setup() {
    const route = useRoute()

    const loading = ref(true)
    const error = ref<TARViewErrors | null>(null)
    const isARActive = ref(false)

    const orderId = ref<number | null>(null)
    const signature = ref<string | null>(null)

    const posters = ref<IQRCodePoster[]>([])

    const arComponent = computed(() => {
      if (!!error.value && !loading.value) {
        return 'ErrorView'
      }

      return 'Loading'
    })

    const isOrderQueryExists = computed(() => {
      return !!orderId.value && !!signature.value
    })

    onMounted(() => {
      // Get poster data from URL
      orderId.value = route.query?.id
        ? parseInt(route.query.id as string)
        : null
      signature.value = route.query?.signature as string

      getData()
    })

    const onStartClick = () => {
      isARActive.value = true
    }

    const getData = async () => {
      loading.value = true

      await asyncTimeout(1500)

      if (!isOrderQueryExists.value) {
        error.value = 'initial'
        loading.value = false
        return
      }

      try {
        const res = await ordersService.getByQRCode(
          orderId.value as number,
          signature.value as string,
        )

        if (res.id !== orderId.value) {
          error.value = 'initial'
          loading.value = false
          return
        }

        posters.value = calculatePostersDimentionsForAR(res.posters)
      } catch (err) {
        error.value = 'load'
        console.error(err)
      } finally {
        loading.value = false
      }
    }

    const calculatePostersDimentionsForAR = (
      posters: IQRCodePoster[],
    ): IQRCodePoster[] => {
      return posters.map((poster) => {
        const { width, height } = calculatePosterWithHeight(poster)
        const position = calculatePosterPosition(poster)
        const scale = calculatePosterScale(poster)
        const rotation = calculatePosterRotation(poster)

        poster.arWidth = width
        poster.arHeight = height

        poster.position = position
        poster.scale = scale
        poster.rotation = rotation

        return poster
      })
    }

    const calculatePosterWithHeight = (poster: IQRCodePoster) => {
      const width = poster.image.widthPx
      const height = poster.image.heightPx

      let arWidth = 2.0
      let arHeight = 2.0

      if (width >= height) {
        arHeight = (2.0 * height) / width
        arHeight = Math.round(arHeight * 100) / 100
      } else {
        arWidth = (2.0 * width) / height
        arWidth = Math.round(arWidth * 100) / 100
      }

      if (poster.arAnchoreProperties?.width) {
        arWidth = poster.arAnchoreProperties.width
      }

      if (poster.arAnchoreProperties?.height) {
        arHeight = poster.arAnchoreProperties.height
      }

      return {
        width: arWidth,
        height: arHeight,
      }
    }

    const calculatePosterPosition = (poster: IQRCodePoster) => {
      const positionX = poster.arAnchoreProperties?.positionX || 0
      const positionY = poster.arAnchoreProperties?.positionY || 0
      const positionZ = poster.arAnchoreProperties?.positionZ || 0

      return `${positionX} ${positionY} ${positionZ}`
    }

    const calculatePosterScale = (poster: IQRCodePoster) => {
      const scaleX = poster.arAnchoreProperties?.scaleX || 1
      const scaleY = poster.arAnchoreProperties?.scaleY || 1
      const scaleZ = poster.arAnchoreProperties?.scaleZ || 1

      return `${scaleX} ${scaleY} ${scaleZ}`
    }

    const calculatePosterRotation = (poster: IQRCodePoster) => {
      const rotationX = poster.arAnchoreProperties?.rotationX || 0
      const rotationY = poster.arAnchoreProperties?.rotationY || 0
      const rotationZ = poster.arAnchoreProperties?.rotationZ || 0

      return `${rotationX} ${rotationY} ${rotationZ}`
    }

    return {
      loading,
      error,
      arComponent,
      posters,
      isOrderQueryExists,
      onStartClick,
      isARActive,
    }
  },
})
