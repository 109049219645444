
import { defineComponent, computed } from 'vue'

import { appName, externalLinks } from '@/config'

export default defineComponent({
  name: 'Footer',

  components: {},

  props: {},

  setup() {
    const year = computed(() => {
      return new Date().getFullYear()
    })

    return {
      year,
      appName,
      externalLinks,
    }
  },
})
