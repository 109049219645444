
import { defineComponent, onMounted, ref, PropType } from 'vue'

import { IQRCodePoster, TPageLoaded } from '@/types'

import AnchorsPreview from './AnchorsPreview.vue'
import Loading from './Loading.vue'

export default defineComponent({
  name: 'AR',

  components: {
    Loading,
    AnchorsPreview,
  },

  props: {
    posters: {
      type: Array as PropType<IQRCodePoster[]>,
      default: () => [],
    },
  },

  setup() {
    const isARLoaded = ref(false)
    const visibleVideoIndex = ref<number | null>(null)

    const aSceneRef = ref()
    const aAssetsRef = ref()
    const zapparImageRefs = ref()
    const videoRefs = ref()

    const isPageLoaded = ref(false)
    const isAssetsLoaded = ref(false)

    onMounted(() => {
      Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get: function () {
          return !!(
            this.currentTime > 0 &&
            !this.paused &&
            !this.ended &&
            this.readyState > 2
          )
        },
      })

      addEventListeners()
    })

    const setPageLoaded = (type: TPageLoaded) => {
      if (type === 'page') {
        isPageLoaded.value = true
      }
      if (type === 'assets') {
        isAssetsLoaded.value = true
      }

      if (isPageLoaded.value && isAssetsLoaded.value) {
        isARLoaded.value = true
      }
    }

    const addEventListeners = () => {
      aSceneRef.value.addEventListener('loaded', () => {
        isARLoaded.value = true
        setPageLoaded('page')
      })

      aAssetsRef.value.addEventListener('loaded', () => {
        setPageLoaded('assets')
      })

      zapparImageRefs.value.forEach((imageRef: any) => {
        // Play video listener
        imageRef.addEventListener('zappar-visible', (evt: any) => {
          console.log(evt)

          const videoIndex = parseInt(
            evt.target.attributes['poster-index'].value,
          )

          visibleVideoIndex.value = videoIndex
          playVideo()
        })

        // Stop video listener
        imageRef.addEventListener('zappar-notvisible', () => {
          stopVideo()
          visibleVideoIndex.value = null
        })
      })
    }

    const playVideo = () => {
      if (visibleVideoIndex.value !== null) {
        videoRefs.value[visibleVideoIndex.value].currentTime = 0
        videoRefs.value[visibleVideoIndex.value].muted = false
        // videoRefs.value[visibleVideoIndex.value]?.load()
        videoRefs.value[visibleVideoIndex.value]?.play()

        // TODO - TEMP FIX
        // setTimeout(() => {
        //   if (!videoRefs.value[visibleVideoIndex.value as number].playing) {
        //     playVideo()
        //   }
        // }, 1000)
      }
    }

    const stopVideo = () => {
      if (visibleVideoIndex.value !== null) {
        videoRefs.value[visibleVideoIndex.value]?.pause()
        videoRefs.value[visibleVideoIndex.value].currentTime = 0
      }
    }

    return {
      zapparImageRefs,
      visibleVideoIndex,
      videoRefs,
      aSceneRef,
      aAssetsRef,
      isARLoaded,
    }
  },
})
