
import { defineComponent, PropType, computed } from 'vue'

import StartButtonLoading from './StartButtonLoading.vue'
import StartButtonText from './StartButtonText.vue'

export default defineComponent({
  name: 'StartButton',

  components: {},

  props: {
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  setup(props) {
    const component = computed(() => {
      return props.isLoading ? StartButtonLoading : StartButtonText
    })

    return {
      component,
    }
  },
})
