
import { defineComponent, PropType } from 'vue'

import { TARViewErrors } from '@/types'
import { externalLinks } from '@/config'

export default defineComponent({
  name: 'ErrorView',

  components: {},

  props: {
    type: {
      type: String as PropType<TARViewErrors | null>,
      default: null,
    },
  },

  setup() {
    return {
      externalLinks,
    }
  },
})
